<template>
  <div class='CreateAndEdit'>
    <el-card class="box-card">
      <div>
        <i class="el-icon-close cha" @click="$router.go(-1)"></i>
        <div class="clearfix">
          位置更换详情
        </div>
      </div>

      <el-divider></el-divider>
      <div class="formbox">
        <el-form :model="chgLocalInfo" :rules="rules" ref="chgLocalInfo">
          <el-form-item label="编号" :label-width="formLabelWidth" prop="id"  class="input-lenght">
            <el-input v-model="chgLocalInfo.id" autocomplete="off" readonly></el-input>
          </el-form-item>
          <h1>仪器1信息</h1>
          <el-divider></el-divider>
          <el-form-item label="仪器条码" :label-width="formLabelWidth" prop="deviceOneCode">
            <el-input  v-model="chgLocalInfo.deviceOneCode" placeholder="请输入标签查询" autocomplete="off"
              readonly class="input-lenght"></el-input>
          </el-form-item>
          <el-form-item label="仪器名称" :label-width="formLabelWidth" prop="deviceOneName" class="input-lenght">
            <el-input v-model="chgLocalInfo.deviceOneName" autocomplete="off" readonly></el-input>
          </el-form-item>
          <el-form-item label='原保存位置' :label-width="formLabelWidth" prop="oneOldLocal" class="input-lenght">
            <el-input v-model="chgLocalInfo.oneOldLocal" autocomplete="off" readonly></el-input>
          </el-form-item>
          <el-form-item label="现保存位置" :label-width="formLabelWidth" prop="oneLocal" class="input-lenght">
            <el-input v-model="chgLocalInfo.oneLocal" autocomplete="off" readonly></el-input>
          </el-form-item>
          <h1>仪器2信息</h1>
          <el-divider></el-divider>
          <el-form-item label="仪器条码" :label-width="formLabelWidth" prop="deviceTwoCode">
            <el-input v-model="chgLocalInfo.deviceTwoCode" placeholder="请输入标签查询" autocomplete="off" readonly 
              class="input-lenght"></el-input>
          </el-form-item>
          <el-form-item label="仪器名称" :label-width="formLabelWidth" prop="deviceTwoName" class="input-lenght">
            <el-input v-model="chgLocalInfo.deviceTwoName" autocomplete="off" readonly></el-input>
          </el-form-item>
          <el-form-item :label="id ? '原保存位置' : '保存位置'" :label-width="formLabelWidth" prop="twoOldLocal"
            class="input-lenght">
            <el-input v-model="chgLocalInfo.twoOldLocal" autocomplete="off" readonly></el-input>
          </el-form-item>
          <div v-if="id">
            <el-form-item label="现保存位置" :label-width="formLabelWidth" prop="twoLocal" class="input-lenght">
              <el-input v-model="chgLocalInfo.twoLocal" autocomplete="off" readonly></el-input>
            </el-form-item>
          </div>
          <h1>更换信息</h1>
          <el-divider></el-divider>
          <el-form-item label="更换日期" :label-width="formLabelWidth" prop="ghDate">
            <el-date-picker v-model="chgLocalInfo.ghDate" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" type="date"
              placeholder="选择日期" readonly>
            </el-date-picker>
          </el-form-item>
          <el-form-item label="更换人员" :label-width="formLabelWidth" prop="ghPersonnel" class="input-lenght">
            <el-input v-model="chgLocalInfo.ghPersonnel" autocomplete="off" readonly></el-input>
          </el-form-item>
          <el-form-item label="更换原因" :label-width="formLabelWidth" prop="ghCause" class="input-lenght">
            <el-input v-model="chgLocalInfo.ghCause" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-form>

      </div>

    </el-card>
  </div>
</template>

<script>
import { queryChgLocalInfo } from '@/api/deviceChgLocal.js'
export default {
  props: {
    id: {
      type: [String, Number]
    }
  },
  data() {
    return {
      chgLocalInfo: {
        id: null,
        deviceOneCode: null,
        deviceOneName: null,
        oneOldLocal: null,
        oneLocal: null,
        deviceTwoCode: null,
        deviceTwoName: null,
        twoOldLocal: null,
        twoLocal: null,
        ghDate: null,
        ghPersonnel: null,
        ghCause: null,
        recordTime: null
      },
      formLabelWidth: "150px",

    }
  },
  mounted() {
    this.loadchgLocalInfo()
  },
  methods: {

    loadchgLocalInfo() {
      queryChgLocalInfo(this.id).then(res => {
        if (res.code === '000000') {
          this.chgLocalInfo = res.t
        }
      })
    },
  }
}

</script>


<style lang="scss" scoped>
.CreateAndEdit {
  .el-card {
    position: relative;
    text-align: left;

    .clearfix {
      text-align: left;
      font-size: 16px;
      font-weight: 700;
    }
    .cha {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 30px;
      color: rgba(54, 55, 56, 0.445);
    }

    .cha:hover {
      cursor: pointer;
      color: #2a2d2f;
    }
  }

  .el-form-item {
    width: 80%;
  }

  .df {
    text-align: right;
  }

  .input-lenght {
    width: 500px;
  }

  .upload1 {
    width: 360px;
    height: 240px;
    margin-right: 20px;
    margin-bottom: 20px;

    .el-upload-dragger,
    .el-upload--text,
    .upload-demo1 {
      width: 100%;
      height: 100%;
    }

    .el-upload-dragger .el-icon-upload {
      margin-top: 60px;
    }

    .el-upload__text {
      margin-top: 20px;
    }
  }

  .bannerbox {
    display: flex;
    flex-wrap: wrap;
  }

  .banners {
    width: 360px;
    margin-right: 20px;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;

    .cha {
      position: absolute;
      right: -10px;
      top: -10px;
      cursor: pointer;
      z-index: 2;
    }

    .el-upload__tip {
      margin-top: 7px;
    }

    .el-upload {
      width: 100%;
    }

    .el-upload-dragger {
      border: none;
      border-radius: 0;
    }
  }

  .avatar {
    width: 100%;
    height: 100%;
  }

  .btns {
    margin-top: 50px;
  }

  .upload-demo {
    width: 360px;
    height: 200px;
  }

  .el-icon-error {
    font-size: 20px;
  }

  .el-icon-error:hover {
    color: red;
  }


}
</style>